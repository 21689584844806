@font-face {
	font-family: lemon-milk;
	font-weight: normal;
	src: url(./assets/font/LemonMilkMedium-mLZYV.otf);
}

@font-face {
	font-family: lemon-milk;
	font-weight: normal;
	font-style: italic;
	src: url(./assets/font/LemonMilkMediumItalic-d95nl.otf);
}

@font-face {
	font-family: lemon-milk;
	font-weight: lighter;
	src: url(./assets/font/LemonMilkRegular-X3XE2.otf);
}

@font-face {
	font-family: lemon-milk;
	font-weight: lighter;
	font-style: italic;
	src: url(./assets/font/LemonMilkRegularItalic-L3AEy.otf);
}

@font-face {
	font-family: lemon-milk;
	font-weight: bold;
	src: url(./assets/font/LemonMilkBold-gx2B3.otf);
}

@font-face {
	font-family: lemon-milk;
	font-weight: bold;
	font-style: italic;
	src: url(./assets/font/LemonMilkBoldItalic-PKZ3P.otf);
}

body {
	background-image: url(./assets/seamless-3047729.jpg);
	background-size: 100vw;
	font-family: Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5 {
	font-family: lemon-milk, Helvetica, Arial, sans-serif;
}

h2 {
	margin-bottom: 0em;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  color: #333;
  background-color: rgba(255, 255, 255, 0.9);
  padding:0 10px 20px 10px;
  box-sizing: border-box;
}

.title-images-wrapper{
	display: flex;
	align-items: center;
	flex: 1 1 70vmin;
	width: 100vw;
	overflow: hidden;
}

.title-images {
	display: flex;
	width: 500vw;
	height:100%;
}

.title-image {
	display: flex;
	align-content: center;
	justify-content: center;
	flex: 0 0 100vw;
	width: 100vw;
	height: 60vmin;
	padding: 0 50px;
	box-sizing: border-box;
}

.App-logo {
	height: 70vmin;
	max-width: calc(100vw - 100px);
}

.tagline {
	text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
	.title-images {
		animation: title-image-slide infinite 10s ease-in-out;
	}
  /* .App-logo {
    animation: App-logo-spin infinite 20s linear;
  } */
}

.App-header {
	flex: 1 1 70vmin;	
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	font-size: calc(10px + 2vmin);
}
header {
	flex: 0 0 1.4em;
}
header i {
	font-weight: lighter;
}

.App-link {
  color: #005d77;
  text-decoration: none;
}

.ingredient-list {
	flex: 0 0;
	width: 100%;
	max-width: 600px;

    background: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 0.5em;
    padding: 0.5em;
}

.delete-btn {
	justify-content: center;
}

.food-control,
.amount-control,
.measurement-control,
.additional-ingredient-input {
	width: 100%;
}

.MuiGrid-item{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}

.recipe-row{
	align-items: flex-start;
}

.recipe-row li {
	font-weight: lighter;
}

.recipe-row.instructions li {
	font-family: Helvetica, Arial, sans-serif;
	font-size: 1.2em;
}

.recipe-btn{
	justify-content: center;
}

.additional-ingredient {
	margin: 5px;
}

.submit-button-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.disclaimer {
	padding: 20px 10px;
	font-size: 8px;
}

.footer {
	margin: 40px 0 10px 0;
	font-size: 0.6em;
}

.btc-addr img {
	height: 8em;
}

@keyframes title-image-slide {
	0%{
		transform: translateX(-80%);
	}
	10%{
		transform: translateX(-80%);
	}
	25%{
		transform: translateX(-60%);
	}
	35%{
		transform: translateX(-60%);
	}
	50%{
		transform: translateX(-40%);
	}
	60%{
		transform: translateX(-40%);
	}
	75%{
		transform: translateX(-20%);
	}
	85%{
		transform: translateX(-20%);
	}
	100%{
		transform: translateX(-0%);
	}
}
